import { React, Fragment, useEffect, useState } from "react";
import Userfront from "@userfront/react";
import { Route, Redirect } from "react-router-dom";

const LandsList = () => {
    const [lands, setLands] = useState([]);

    const getLands = async () => {
        try {
            // If the user is logged in, show the dashboard
            const userData = JSON.stringify(Userfront.user, null, 2);
            const parsedUserData = JSON.parse(userData);
            const userID = parsedUserData.data["eigandiID"];

            const response = await fetch("https://min.eignamork.is/api/lands/owners/" + userID, {
                headers: {
                    Authorization: `Bearer ${Userfront.accessToken()}`
                }
            })

            const jsonData = await response.json();

            setLands(jsonData);
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        getLands();
    }, [])

    function renderFn({ location }) {
        // If the user is not logged in, redirect to login
        if (!Userfront.accessToken()) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location },
                    }}
                />
            );
        }

        return (
            <Fragment>
                <div className="my-5">
                    <h1 className="mt-5">Mínar landeignir</h1>
                </div>
                <table className="table mt-5 text-center">
                    <thead>
                        <tr>
                            <th>Landeignanúmer</th>
                            <th>Heiti</th>
                            <th>Dags skráð</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lands.map(land => (
                            <tr key={land.landeignid}>
                                <td>L{land.landnr}</td>
                                <td>{land.nafnlandeignar}</td>
                                <td>{land.dags.split('T')[0]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Fragment>
        )
    }

    return <Route render={renderFn} />;
}

export default LandsList;