import { React, Fragment, useState } from "react";
import Userfront from "@userfront/react";

const EditPdf = ({ pdf }) => {
    const [skyring, setSkyring] = useState(pdf.skyring);
    const [skyringError, setSkyringError] = useState("");

    const handleValidation = () => {
        var skyringError;

        var regex = /^[A-Za-z0-9ðíóæöéá ]+$/

        // Validate skyring
        if (!regex.test(skyring)) {
            skyringError = "Ólöglegir stafir";
        }

        if (skyring.trim() === '') {
            skyringError = "Heiti má ekki vera tómt";
        }

        if (skyringError) {
            setSkyringError(skyringError);

            return false;
        }

        return true;
    }

    const updateLand = async (e) => {
        e.preventDefault();

        setSkyringError("");

        if (handleValidation()) {
            try {
                const body = { Skyring: skyring };

                await fetch(`https://min.eignamork.is/api/pdfs/${pdf.skjalid}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Userfront.accessToken()}`
                    },
                    body: JSON.stringify(body)
                });

                window.location = "/skjol";
            } catch (err) {
                console.error(err.message);
            }
        }
    }

    return (
        <Fragment>
            <button type="button" className="btn btn-warning" data-toggle="modal" data-target={`#id${pdf.skjalid}`}>
                Breyta
            </button>

            <div className="modal fade" id={`id${pdf.skjalid}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Breyta skjali</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="skyring" className="col-form-label">
                                Skýring
                            </label>
                            <input
                                id="skyring"
                                type="text"
                                className="form-control"
                                value={skyring}
                                onChange={e => setSkyring(e.target.value)}
                            />
                            {skyringError}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setSkyring(pdf.skyring)}>Loka</button>
                            <button type="button" className="btn btn-primary" onClick={e => updateLand(e)}>Vista breytingar</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EditPdf;