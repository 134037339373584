import React from "react";

class Alert extends React.Component {
    render() {
        if (!this.props.message) return "";
        return (
            <div className="alert-box error">
                <span>error: </span>{this.props.message}
            </div>
        )
    }
}

export default Alert;