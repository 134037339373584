// Define the Signup form component
import React, { Component } from 'react';
import Userfront from "@userfront/react";
import Alert from "./Alert";

class SignupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            name: "",
            phone: "",
            email: "",
            address: "",
            postalCode: "",
            password: "",
            passwordVerify: "",
            alertMessage: "",
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setAlertMessage = this.setAlertMessage.bind(this);
    }

    // Whenever an input changes value, change the corresponding state variable
    handleInputChange(event) {
        event.preventDefault();
        const target = event.target;
        this.setState({
            [target.name]: target.value,
        });
    }

    // Handle the form submission by calling Userfront.signup()
    async handleSubmit(event) {
        event.preventDefault();

        // Reset the alert to empty
        this.setAlertMessage();

        // Verify that the passwords match
        if (this.state.password !== this.state.passwordVerify) {
            return this.setAlertMessage('Passwords must match');
        }

        const body = {
            Kennitala: this.state.id,
            Nafn: this.state.name,
            Simi: this.state.phone,
            Netfang: this.state.email,
            Heimilisfang: this.state.address,
            Postnr: this.state.postalCode,
            ErFyrirtaeki: false,
            LeyfiSkjol: false,
            LeyfiGrannar: false,
            LeyfiTeika: false,
        };

        fetch("https://min.eignamork.is/api/owners", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }).then(async (response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const jsonData = await response.json();

            // Call Userfront.signup()
            Userfront.signup({
                method: "password",
                email: this.state.email,
                password: this.state.password,
                name: this.state.name,
                data: {
                    eigandiID: jsonData["eigandiid"],
                },
            }).catch((error) => {
                console.log(error.message);
            })
        }).catch(function (error) {
            console.log(error);
        });
    }

    setAlertMessage(message) {
        this.setState({ alertMessage: message });
    }

    render() {
        return (
            <div className="formDiv">
                <h2 className="mb-3">Stofna aðgang</h2>
                <Alert message={this.state.alertMessage} />
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label>
                            Nafn:
                            <input
                                name="name"
                                type="text"
                                value={this.state.name}
                                className="form-control"
                                onChange={this.handleInputChange}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            Netfang:
                            <input
                                name="email"
                                type="email"
                                value={this.state.email}
                                className="form-control"
                                onChange={this.handleInputChange}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            Sími:
                            <input
                                name="phone"
                                type="number"
                                value={this.state.phone}
                                className="form-control"
                                onChange={this.handleInputChange}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            Kennitala:
                            <input
                                name="id"
                                type="text"
                                value={this.state.id}
                                className="form-control"
                                onChange={this.handleInputChange}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            Heimilisfang:
                            <input
                                name="address"
                                type="text"
                                value={this.state.address}
                                className="form-control"
                                onChange={this.handleInputChange}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            Póstnúmer:
                            <input
                                name="postalCode"
                                type="number"
                                value={this.state.postalCode}
                                className="form-control"
                                onChange={this.handleInputChange}
                            />
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            name="isCompany"
                            type="checkbox"
                            className="form-check-input"
                        />
                        <label>Fyrirtæki</label>
                    </div>
                    <div className="form-group">
                        <label>
                            Lykilorð
                            <input
                                name="password"
                                type="password"
                                value={this.state.password}
                                className="form-control"
                                onChange={this.handleInputChange}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            Lykilorð aftur
                            <input
                                name="passwordVerify"
                                type="password"
                                value={this.state.passwordVerify}
                                className="form-control"
                                onChange={this.handleInputChange}
                            />
                        </label>
                    </div>
                    <button className="btn btn-primary" type="submit">Sign up</button>
                </form>
            </div >
        );
    }
}

export default SignupForm;