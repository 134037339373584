import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineDownload } from 'react-icons/ai';
import { Route, Redirect } from "react-router-dom";
import Userfront from "@userfront/react";
import ReactLoading from "react-loading";

import EditPdf from "./EditPdf";

const PdfList = () => {
    const [selectedLandeignId, setselectedLandeignId] = useState('');
    const [neighborPdfs, setneighborPdfs] = useState([]);
    const [lands, setLands] = useState([]);
    const [owner, setOwner] = useState("");
    const [pdfs, setPdfs] = useState([]);

    const getPdfs = async (id) => {
        try {
            const response = await fetch("https://min.eignamork.is/api/pdfs/" + id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Userfront.accessToken()}`,
                    credentials: 'include'
                },
            });
            const jsonData = await response.json();

            setPdfs(jsonData);
        } catch (err) {
            console.error(err.message);
        }
    }

    const dropdownChanged = async (e) => {
        setselectedLandeignId(e.target.value);

        await getPdfs(e.target.value);

        await fetch("https://min.eignamork.is/api/neighbors/" + e.target.value, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Userfront.accessToken()}`,
                credentials: 'include'
            },
        }).then(async (response) => {
            const pdfJsonData = await response.json();
            console.log(pdfJsonData);

            setneighborPdfs(pdfJsonData);
        })
    }

    useEffect(() => {
        try {
            // If the user is logged in, show the dashboard
            const userData = JSON.stringify(Userfront.user, null, 2);
            const parsedUserData = JSON.parse(userData);
            const userID = parsedUserData.data["eigandiID"];

            if (userID) {
                fetch(`https://min.eignamork.is/api/owners/${userID}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Userfront.accessToken()}`
                    },
                }).then(async (response) => {
                    const jsonData = await response.json();
                    setOwner(jsonData);
                }).catch((err) => {
                    console.log(err.message);
                })

                fetch("https://min.eignamork.is/api/lands/owners/" + userID, {
                    headers: {
                        Authorization: `Bearer ${Userfront.accessToken()}`
                    }
                }).then(async (response) => {
                    var landsJsonData = await response.json();

                    if (landsJsonData[0]) {
                        setLands(landsJsonData);
                        setselectedLandeignId(landsJsonData[0]["landeignid"]);
                        getPdfs(landsJsonData[0]["landeignid"]);

                        fetch("https://min.eignamork.is/api/neighbors/" + landsJsonData[0]["landeignid"], {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${Userfront.accessToken()}`,
                                credentials: 'include'
                            },
                        }).then(async (response) => {
                            const pdfJsonData = await response.json();
                            console.log(pdfJsonData);
                            setneighborPdfs(pdfJsonData);
                        })
                    }
                })
            }
        } catch (err) {
            console.error(err.message);
        }
    }, [])

    function renderFn({ location }) {
        // If the user is not logged in, redirect to login
        if (!Userfront.accessToken()) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location },
                    }}
                />
            );
        }

        if (!(owner === "")) {
            if (!owner.leyfiskjol) {
                return (
                    <Fragment>
                        <div className="container my-5">
                            <h3 className="mb-3">Aðgangur læstur</h3>
                            <p>Því miður ert þú ekki með aðgang að þessu svæði.</p>
                            <p>Hægt er að kaupa aðgang á <a href="https://eignamork.is/landeignamork/thjonusta/" target="_blank" rel="noreferrer">eignamork.is</a></p>
                        </div>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>
                        <div className="input-group select-land mt-4">
                            <div>
                                <label>Valin landeign</label>
                            </div>
                            <div>
                                {lands.length > 0 &&
                                    <select
                                        id="land-select"
                                        value={selectedLandeignId}
                                        onChange={dropdownChanged}
                                        className="form-select float-right mx-2 mb-2"
                                        aria-label="Landeignanúmer select"
                                    >
                                        {lands.map(land => (
                                            <option
                                                key={land.landeignid}
                                                value={land.landeignid}
                                            >
                                                {land.nafnlandeignar} - {land.landnr}
                                            </option>
                                        ))}
                                    </select>
                                }
                            </div>
                        </div>
                        <div className="my-3">
                            <h1 className="mt-4">Mín skjöl</h1>
                        </div>
                        {pdfs.length > 0 ?
                            <table className="table mt-2 text-center">
                                <thead>
                                    <tr>
                                        <th>Landeignanúmer</th>
                                        <th>Skjalgerð</th>
                                        <th className="date">Innlesið</th>
                                        <th>Skýring</th>
                                        <th>Skjal</th>
                                        <th className="changePdf">Breyta</th>
                                    </tr>
                                    <tr>

                                    </tr>
                                </thead>
                                <tbody>
                                    {pdfs.map(pdf => (
                                        <tr key={pdf.skjalid}>
                                            <td>L{pdf.landnr}</td>
                                            <td>{pdf.nafn}</td>
                                            <td className="date">{pdf.dags.split('T')[0]}</td>
                                            <td>{pdf.skyring}</td>
                                            <td>
                                                <button id="downloadButton" type="button" className="btn"> <a href={`data:application/pdf;base64,${pdf.pdfdoc}`} download="eignamork.pdf"><AiOutlineDownload /></a></button>
                                            </td>
                                            <td className="changePdf">
                                                <EditPdf pdf={pdf} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            :
                            <p className="mb-4">Engin skjöl fundust.</p>
                        }
                        <div className="my-3">
                            <h1 className="mt-5">Skjöl nágranna</h1>
                        </div>
                        {neighborPdfs.length > 0 ?
                            <table className="table my-5 text-center">
                                <thead>
                                    <tr>
                                        <th>Landeignanúmer</th>
                                        <th>Skjalgerð</th>
                                        <th className="date">Innlesið</th>
                                        <th>Skýring</th>
                                        <th>Skjal</th>
                                    </tr>
                                    <tr>

                                    </tr>
                                </thead>
                                <tbody>
                                    {neighborPdfs.map(pdf => (
                                        <tr key={pdf.skjalid}>
                                            <td>L{pdf.landnr}</td>
                                            <td>{pdf.nafn}</td>
                                            <td className="date">{pdf.dags.split('T')[0]}</td>
                                            <td>{pdf.skyring}</td>
                                            <td>
                                                <button
                                                    id="downloadButton"
                                                    type="button"
                                                    className="btn"
                                                >
                                                    <a
                                                        href={`data:application/pdf;base64,${pdf.pdfdoc}`}
                                                        download="eignamork.pdf"
                                                    >
                                                        <AiOutlineDownload />
                                                    </a>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            :
                            <p className="mb-4">Engin skjöl fundust.</p>
                        }
                    </Fragment>
                )
            }
        }

        return (
            <div className="h-100 d-flex flex-row justify-content-center align-items-center mt-5">
                <ReactLoading className="mt-5" type={"spokes"} color={"black"} />
            </div>
        )
    }

    return <Route render={renderFn} />;
}

export default PdfList;